import React, { useEffect, useState } from 'react';
import { deleteTaskbyId, getMyTask, getTaskByProjectId, getTasksByProjectId } from '../services/TaskService';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import GanttChartComponent from '../components/treegridGantt/Gantt/GanttChartComponent';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Box, Stack, Typography, Grid } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { taskReschedule } from '../services/ProjectService';
import dayjs from 'dayjs';
import AddTaskDialog from '../components/bidDetails/AddTaskDialog';
import AddIcon from '@mui/icons-material/Add';

const GanttParentPage: React.FC<any> = () => {
    const { accessToken } = useOidcAccessToken();
    const [ganttData, setGanttData] = useState<any[]>([]);
    const [tasks, setTasks] = useState<any>([]);
    const location = useLocation();
    let data = location?.state?.project;
    console.log('data', data);
    let navigate = useNavigate();
    const [openDialogs, setOpenDialogs] = React.useState(false);
    const [taskList, setTaskList] = useState([] as any[]);

    useEffect(() => {
        const fetchProjectData = async () => {
            const response = await getTasksByProjectId(data.id, accessToken);
            
            const fetchedTasks = response.data;
            setTasks(fetchedTasks);
            console.log('tasks', tasks);
        };

        fetchProjectData();
    }, [accessToken, data.id]);


    const handleGanttDataUpdate = async (updatedData: any) => {
        if (!updatedData?.Changes || updatedData?.Changes.length === 0) {
            console.warn('No changes detected in the updated data.');
            return;
        }
    
        for (const updatedItem of updatedData.Changes) {
            console.log('Processing updated item:', updatedItem);
    
            const taskId = parseInt(updatedItem?.id);
            // const matchingTask = tasks.find((task: any) => task?.taskId === taskId);
            const matchingTask = tasks.map((phase: any) => phase.tasks).flat().filter((task: any) => task?.taskId === taskId); // This will return all matching tasks

    console.log('matching task',matchingTask[0]);
            if (!matchingTask) {
                console.warn(`No matching task found for taskId: ${taskId}`);
                continue;
            }
    
            if (updatedItem.Deleted) {
                console.log('Deleting task:', matchingTask);
    
                try {
                    const result = await deleteTaskbyId(matchingTask[0], accessToken);
                    console.log(`Task ${taskId} deleted successfully:`, result);
                } catch (error) {
                    console.error(`Error deleting task ${taskId}:`, error);
                }
            } else {
                console.log('Updating task:', matchingTask);
    
                try {
                    const wbsParts = (matchingTask[0].wbs || "").split(".").slice(0, -1);
                    const startDate = updatedItem?.Start 
                        ? dayjs(updatedItem?.Start).format('YYYY-MM-DDTHH:mm:ss[Z]') 
                        : matchingTask.startTime;
                    const endDate = dayjs(updatedItem?.End).format('YYYY-MM-DDTHH:mm:ss[Z]');
    
                    // Perform rescheduling
                    reschedule(
                        taskId,
                        wbsParts.join("."),
                        startDate,
                        endDate,
                        updatedItem
                    );
    
                    console.log(`Task ${taskId} updated successfully.`);
                    setGanttData(updatedData); // Update the Gantt chart
                } catch (error) {
                    console.error(`Error updating task ${taskId}:`, error);
                }
            }
        }
    };
    
    const reschedule = async (taskId: any, wbs: any, startTime: any, endTime: any,updatedata:any) => {

        console.log('updatedata',updatedata)

        try {
            const result = await taskReschedule(data.id, taskId, wbs, startTime, endTime, accessToken);
            console.log('result',result);

            const response = await getTaskByProjectId(data.id, accessToken);
        } catch (error) {
            console.error('Error in reschedule:', error);
        }
    };

    const handleClose = (state: any) => {
        navigate('/soldproduction');
    };
    const clickAddManual = async () => {
        const response1 = await getMyTask(accessToken);
        setTaskList(response1.data);
        setOpenDialogs(true);
    }

    const handleAddTaskDialog = () => {
        setOpenDialogs(false);
    }

    return (

        <>
            <Grid item xs={12}>
                <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                    mb={3}
                >
                    <Button
                        onClick={handleClose}
                        variant="contained"
                        startIcon={<ArrowBackIcon />}
                        className="greyTonalButton"
                        sx={{ marginLeft: "32px" }}
                    >
                        Previous
                    </Button>
                    <Button
                        onClick={clickAddManual}
                        variant="contained"
                        startIcon={<AddIcon />}
                        className="createbidbtnnext"
                        sx={{ marginRight: "32px" }}
                    >
                        Add Task
                    </Button>
                </Box>
                <Typography
                    variant="h4"
                    sx={{ mb: 2, color: 'text.primary' }}
                >
                    Gantt Chart View  - Order No :{data.jobNo}
                </Typography>
            </Grid>

            <GanttChartComponent
                selected={tasks}
                onGanttDataUpdate={handleGanttDataUpdate}
            />
            {openDialogs &&
                <AddTaskDialog open={openDialogs}
                    onClose={handleAddTaskDialog}
                    state={"Gps"}
                    projectId={data.id}
                    id={taskList[0]?.taskId} />}
        </>
    );
};

export default GanttParentPage;
