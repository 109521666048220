import React, { useEffect, useState } from 'react';
import { Box, CssBaseline, Typography, CircularProgress } from '@mui/material';
import { useOidcAccessToken } from '@axa-fr/react-oidc';
import { getResourceByUid } from '../../../services/ResourceService';
import { getAllHolidays } from '../../../services/Holiday';

interface GanttChartComponentProps {
  onGanttDataUpdate: (data: any) => void;
  selected: any;
}

const GanttChartComponent: React.FC<GanttChartComponentProps> = ({ onGanttDataUpdate, selected }) => {
  // console.log('selected', selected.tasks);
  const [ganttData, setGanttData] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const { accessToken } = useOidcAccessToken();
  const [holidays, setHolidays] = useState<any[]>([]);



  useEffect(() => {
    const fetchAssignees = async (task: any) => {
      if (task.assignees && task.assignees.length > 0) {
        const assigneeNames = await Promise.all(
          task.assignees.map(async (resourceId: any) => {
            try {
              const resource = await getResourceByUid(resourceId, accessToken);
              return resource?.data?.name || '';
            } catch (err) {
              console.error(`Error fetching resource for ID ${resourceId}:`, err);
              return 'Unknown';
            }
          })
        );
        return assigneeNames.join(', ');
      }
      const res = await getAllHolidays(accessToken);
      setHolidays(res.data);
      const dates = holidays.map(holiday => holiday.date);
      return '';
    };

    const isHoliday = (date: Date) => {
      const holidayDates = holidays.map((holiday) => new Date(holiday.date).toISOString().split('T')[0]); // Format: 'YYYY-MM-DD'
      console.log('holidayDates', holidayDates);
      const dateStr = date.toISOString().split('T')[0];
      return holidayDates.includes(dateStr);
    };


    const adjustForHoliday = (date: Date) => {
      while (isHoliday(date)) {
        date.setDate(date.getDate() + 1);
      }
      return date;
    };
    

    const processTasks = async () => {
      setIsLoading(true);
    
      if (selected && selected.length > 0) {
        const transformedTasks: any[] = [];
        let phaseIndex = 0;
    
        for (const phase of selected) {
          let previousTaskEndTime: Date | null = null;
    
          for (let taskIndex = 0; taskIndex < phase.tasks.length; taskIndex++) {
            const task = phase.tasks[taskIndex];
            const assigneeNames = await fetchAssignees(task);
    
            if (!task.taskType || task.taskType === '') {
              continue;
            }
    
            let startTime = task.startTime;
            let endTime;
    
            if (startTime === null && previousTaskEndTime) {
              startTime = previousTaskEndTime;
            }
    
            if (task.endTime === null) {
              if (previousTaskEndTime) {
                endTime = new Date(previousTaskEndTime);
                endTime.setMinutes(endTime.getMinutes() + 1);
              } else {
                console.error(`End time for previous task is invalid for task with ID: ${task.taskId}`);
                continue;
              }
            } else {
              endTime = new Date(task.endTime);
            }
                if (endTime) {
              endTime = adjustForHoliday(endTime);
            }
    
            previousTaskEndTime = endTime;
    
            const childTask = {
              id: task.taskId,
              TaskId: task.taskId,
              PrimaryTaskId: `Phase-${phaseIndex}`,
              Task: task.taskName,
              Start: startTime ? new Date(startTime).toISOString() : null,
              End: endTime ? endTime.toISOString() : null,
              Dependency: task.dependencies,
              Duration: task.estimatedHours,
              taskType: task.taskType,
              PrimaryTask: `Phase ${phaseIndex + 1}: ${phase.phase}`,
              Resource: assigneeNames,
            };
    
            transformedTasks.push(childTask);
          }
          phaseIndex++;
        }
    
        setGanttData(transformedTasks);
      }
    
      setIsLoading(false);
    };
    
    
    processTasks();
  }, [selected]);

  useEffect(() => {
    if (ganttData.length > 0 && window.StartTreeGrid) {
      window.StartTreeGrid();
      window.Grids.OnCustomAjax = (G: any, IO: any, data: any, func: any) => {
        MyAjax(IO, data);
      };
    } else {
      console.log('Gantt chart data is empty or StartTreeGrid is not defined');
    }
  }, [ganttData]);


  const MyAjax = (Url: any, data: string) => {
    try {
      console.log('MyAjax received data:', data);
      const parsedData = JSON.parse(data);
      console.log('Parsed data:', parsedData);
      setGanttData(parsedData);
      onGanttDataUpdate(parsedData);
    } catch (error) {
      console.error('Failed to parse data in MyAjax:', error);
    }
  };



  return (
    <React.Fragment>
      <CssBaseline />
      <Box component="section" sx={{ p: 2 }}>
        {isLoading ? (
          <Box display="flex" justifyContent="center" alignItems="center" height="400px">
            <CircularProgress />
            <Typography marginLeft={2}>Loading Gantt Chart...</Typography>
          </Box>
        ) : (
          <div className="ExampleBorder">
            <div className="ExampleMain" style={{ width: '100%' }} id="TreeGridMainTag">
              <bdo
              
                debug=""
                id="SampleGrid"
                layout_url="Layouts/GanttTreeDef.js"
                data_data={JSON.stringify({ Body: [ganttData] })}
                  // upload_url="TreeGridSample.aspx"
                  //  upload_data="TGData" 
                  //  upload_xml="1" 
                  //  upload_param="Save"
                // upload_url="http://localhost:8000/set?table=GanttTree&idcol=id"
                upload_url={`http://localhost:8080/gms-provider/api/v2/tasks/projectByTask,${accessToken}`}
                style={{ width: '100%', height: '100%', }}
                // exportpdf_type="Cfg,Def,Cols,All"
              />
            </div>
          </div>

        )}
                          

      </Box>
    </React.Fragment>
  );
};

export default GanttChartComponent;




